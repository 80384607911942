import React from "react";
import { useForm } from "react-hook-form";
import styled from 'styled-components'
import media from "styled-media-query";
import { gold, turq } from '../../utilities/colors';

const Container = styled.div`
      /* border: 1px blue dashed; */
    grid-area: 5 / 1 / 6 / -1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: black; */
    padding-bottom: 50px;
    /* padding-top: 50px; */
`;

const Gradient = styled.div`
    grid-area: 3 / 1 / 4 / -1;
    background-size: cover;
    background-repeat: no-repeat;
`;

const Form = styled.form`
    /* border: 1px blue dashed; */
    width: auto;
    display: flex;
    flex-direction: column;
    align-self: center;
    `;

const HeaderText = styled.h1`
    /* border: 1px red dashed; */
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 66px;
    letter-spacing: -0.01em;
    z-index: 1;
    color: white;
    align-self: center;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 20px;
    ${media.lessThan("medium")`
      font-size: 25px;
      margin-bottom: 10px;
    `}
    ${media.between("medium", "large")`
    font-size: 30px;
    `}
`;

const Input = styled.input`
    /* border: 1px blue dashed; */
    width: 350px;
    height: 49.65px;
    outline: none;
    background-color: #000;
    border: 1px solid ${turq};
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px ${turq};
    color: ${turq};
    font-family: 'Blinker', sans-serif;
    font-style: normal;
    font-size: 15px;
    letter-spacing: 0.1em;
    font-weight: 400;
    padding: 10px;
    :focus{
      border: 2px solid ${turq};
    }
    ::placeholder{
      color: white;
      font-weight: 200;
    }
    ${media.lessThan("medium")`
    width: 260px;
    `}
    ${media.between("medium", "large")`
      width: 300px;
    `}
    ${media.greaterThan("large")`
    `}
`;

const Textarea = styled.textarea`
    resize: none;
    width: 350px;
    height: 173px;
    outline: none;
    background-color: #000;
    border: 1px solid ${turq};
    box-shadow: 0px 0px 5px ${turq};
    color: ${gold};
    font-family: 'Blinker', sans-serif;
    font-style: normal;
    font-size: 15px;
    letter-spacing: 0.1em;
    font-weight: 400;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 10px;
    :focus{
      border: 2px solid ${turq};
    }
    ::placeholder{
      color: white;
      font-weight: 200;
    }
    ${media.lessThan("medium")`
    width: 260px;
    `}
    ${media.between("medium", "large")`
    width: 300px;
    `}
    ${media.greaterThan("large")`
    `}
`;

const Button = styled.button`
    /* border: 1px blue dashed; */
    width: 230px;
    height: 55px;
    margin-top: 20px;
    /* margin-right: 10px; */
    background-color: #000;
    border: 3px solid ${turq};
    box-shadow: 0px 0px 5px ${turq};
    box-sizing: border-box;
    border-radius: 36px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: ${turq};
    align-self: center;
    cursor: pointer;
    transition-duration: 0.5s;
    &:hover{
      background-color: ${turq};
      color: #000;
      opacity: 0.9;
      transform: scale(1.05);
    }
    ${media.lessThan("medium")`
      margin-right: 0px;
    `}
    ${media.between("medium", "large")`
      align-self: center;
    `}
`;

const Error = styled.p`
    /* border: 1px blue dashed; */
    color: red;
    margin-top: 5px;
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 129.5%;
    letter-spacing: 0.05em;
    margin-bottom: 20px;
`;

const HiddenError = styled(Error)`
    /* border: 1px blue dashed; */
    color: transparent;
`;

const Success = styled.p`
    /* border: 1px blue dashed; */
    color: green;
    margin-top: 10px;
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 129.5%;
    letter-spacing: 0.05em;
    width: 255.55px;
    align-self: flex-end;
    text-align: center;
    ${media.lessThan("medium")`
      align-self: center;
    `}
    ${media.between("medium", "large")`
      align-self: center;
    `}
`;

 export default function ContactUs() {

    const { 
        register, 
        handleSubmit, 
        reset,
        formState: { errors, isSubmitSuccessful }
    } = useForm({reValidateMode: "onSubmit"});

    const onSubmit = async (data) => {
      console.log("Got the data", data)

      try {
        let result = await fetch(
          "https://gooanlpy69.execute-api.us-east-2.amazonaws.com/prod/contactus",
          {
            method: "post",
            mode: "no-cors",
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
            },
            body: JSON.stringify({
              fullname: data.fullname,
              company: data.company,
              email: data.email,
              message: data.message,
            }),
          }
        );

        reset({ fullname: '', company: '', email: '', message: '' });
      } catch (e) {
        console.log("BIG ERROR", e);
      }
    };

    console.log("Here are the errors ", errors)

  return (
    <Container id="contact">
      <HeaderText>Get in Touch</HeaderText>
        <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Input type="text" name="fullname" placeholder="Name" {...register('fullname', {required: true, maxLength: 20})} />
            {errors.fullname ? <Error>Name required</Error> :<HiddenError>1</HiddenError>}

            <Input type="text" name="email" placeholder="Email" {...register('email', {required: true, maxLength: 30, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})} />
            {errors.email ? <Error>Valid email required</Error>:<HiddenError>1</HiddenError>}

            <Textarea type="textarea" rows={6} name="message" placeholder="Message" {...register('message', {maxLength: 200})} />

            <Button type="submit">SEND</Button>
            {(isSubmitSuccessful) ? <Success>Message Sent</Success> : null}
        </Form>
    </Container>
  );
}
