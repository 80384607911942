import React, { Component } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { Parallax } from "react-parallax";
import Landing from "./Landing";
import ContactUs from "./ContactUs";
import Footer from "./Footer";
import GallerySection from "./Gallery";
import ArtistInfo from "./ArtistInfo";
import PaintingParallax from "./PaintingParallax";
import ImageBg from "./../../images/bg_hero.jpg";
import ImageBg2 from "./../../images/bg_Graffiti.jpg";
import ImgLand1 from "./../../images/bg_landscape_mountains.jpg";

const GridContainer = styled.div`
  display: grid;
  grid-template: 100vh auto auto auto auto auto / 100vw;
  overflow: hidden;
`;

const BackgroundImage = styled.div`
  grid-area: 1 / 1 / 2 / 3;
  background-image: url(${ImgLand1});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: none;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: fixed;
  }
`;

const BackgroundImage2 = styled.div`
  grid-area: 4 / 1 / 6 / -1;
  background-image: url(${ImageBg2});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  /* background-position: fixed; */
  overflow: none;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: fixed;
  }
`;

export default class HomeLayout extends Component {
  render() {
    return (
      <GridContainer>
        <BackgroundImage />
        <Landing />
        <ArtistInfo />
        <PaintingParallax />
        <BackgroundImage2 />
        <GallerySection />
        <ContactUs />
        <Footer />
      </GridContainer>
    );
  }
}
