import React from "react";
import HomeLayout from './ui/home/HomeLayout'
import { Reset } from "styled-reset";

function App() {
  return (
    <div>
        <Reset/>
        <HomeLayout/>
    </div>
  );
}

export default App;
