import React, { Component } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { gold } from "../../utilities/colors";
import Gallery from "react-photo-gallery";
import Aos from "aos";
import "aos/dist/aos.css";
import ImageBg from "./../../images/bg_Graffiti.jpg";

import ImageSq6 from "./../../images/art_square-5.jpg";
import ImgTechDown from "./../../images/ic_tech_down.png";
import ImgPort1 from "./../../images/hq_portrait_blackDetail.jpg";
import ImgPort2 from "./../../images/hq_portrait_blackLarge.jpg";
import ImgPort3 from "./../../images/hq_portrait_blukay.jpg";
import ImgPort4 from "./../../images/hq_portrait_Detail.jpg";
import ImgPort5 from "./../../images/art_portrait1.jpg";
import ImgPort6 from "./../../images/art_portrait-2.jpg";
import ImgPort7 from "./../../images/art_portrait2.jpg";
import ImgPort8 from "./../../images/art_portrait3.jpg";
import ImgPort9 from "./../../images/art_portrait4.jpg";
import ImgPort10 from "./../../images/art_portrait5.jpg";
import ImgPort12 from "./../../images/art_portrait7.jpg";
import ImgLand1 from "./../../images/hq_landscape_mountains.jpg";
import ImgLand2 from "./../../images/hq_landscape_theBlueSway.jpg";
import ImgLand4 from "./../../images/art_landscape2.jpg";
import ImgLand5 from "./../../images/art_landscape3.jpg";
import Dre1 from "./../../images/20230613_052850-min.jpg";
import Dre2 from "./../../images/dre2.jpeg";
import Dre3 from "./../../images/dre3-min.jpeg";
import Dre4 from "./../../images/dre4-min.jpeg";
import Dre5 from "./../../images/dre5-min.jpeg";
import Dre6 from "./../../images/dre6-min.jpeg";

const BackgroundImage = styled.div`
  grid-area: 4 / 1 / 5 / -1;
  background-image: url(${ImageBg});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  /* background-position: fixed; */
  overflow: none;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: fixed;
  }
`;

const Container = styled.div`
  /* border: 1px blue dashed; */
  max-width: 1440px;
  width: 1000px;
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-self: center;
  /* margin-bottom: 20px; */
  ${media.lessThan("medium")`
    width: 70%;
        `}
  ${media.between("medium", "large")`
    width: 80%;
    `}
    ${media.greaterThan("large")`
    `}
`;

const HeaderTextContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 130px;
  margin-bottom: 130px;
  ${media.lessThan("medium")`
    margin-top: 50px;
    margin-bottom: 50px;
        `}
`;

const TechImgDownContainer = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
`;

const TechImgDown = styled.img`
  /* border: 1px green dashed; */
  width: 47.2px;
  height: 288.22px;
  margin-top: -100px;
  align-self: center;
  z-index: -1;
  ${media.lessThan("medium")`
    margin-right: 0px;
    `}
  ${media.between("medium", "large")`
        /* margin-right: 200px; */
    `}
`;

const TechImgDown2 = styled(TechImgDown)`
  /* align-self: start; */
  margin-top: -340px;

  ${media.lessThan("medium")`
        display: none;
    `}
  ${media.between("medium", "large")`
        display: none;
    `}
`;

const HeaderText = styled.h1`
  /* border: 1px red dashed; */
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 66px;
  letter-spacing: -0.01em;
  z-index: 1;
  color: white;
  align-self: center;
  margin-right: 5px;
  margin-left: 5px;
  /* text-shadow: 0px 0px 5px ${gold};  */
  ${media.lessThan("medium")`
    font-size: 40px;
    /* margin-bottom: 10px; */
    `}
  ${media.between("medium", "large")`
        font-size: 50px;
    `}
`;

const BlurbText = styled.p`
  /* border: 1px red dashed; */
  width: 600px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.05em;
  z-index: 1;
  color: ${gold};
  margin-bottom: 60px;
  align-self: center;
  margin-right: 5px;
  margin-left: 5px;
  text-align: center;
  /* text-shadow: 0px 0px 5px ${gold};  */
  ${media.lessThan("medium")`
        width: 300px;
    `}
  ${media.between("medium", "large")`
    `}
`;

export default class GallerySection extends Component {
  componentDidMount() {
    Aos.init();
  }

  render() {
    return (
      <BackgroundImage>
        {/* <BackgroundImage /> */}
        <Container id="gallery">
          <HeaderTextContainer>
            <HeaderText
              data-aos="fade-in"
              data-aos-duration="1000"
              data-aos-easing="ease-in-sine"
            >
              GALLERY
            </HeaderText>
          </HeaderTextContainer>
          <Gallery photos={photos} direction={"column"} />
          <TechImgDownContainer>
            <TechImgDown
              src={ImgTechDown}
              data-aos="slide-down"
              data-aos-duration="3000"
              data-aos-easing="ease-in-sine"
            />
          </TechImgDownContainer>
        </Container>
      </BackgroundImage>
    );
  }
}

const photos = [
  {
    src: Dre3,
    width: 1,
    height: 1,
  },

  {
    src: Dre1,
    width: 2,
    height: 3,
  },
  {
    src: Dre6,
    width: 2,
    height: 3,
  },

  {
    src: Dre2,
    width: 1,
    height: 1,
  },
  {
    src: ImgPort1,
    width: 3,
    height: 4,
  },
  {
    src: ImgLand1,
    width: 3,
    height: 2,
  },
  {
    src: ImgPort7,
    width: 3,
    height: 4,
  },
  {
    src: Dre4,
    width: 1,
    height: 1,
  },
  {
    src: ImgPort12,
    width: 3,
    height: 4,
  },

  {
    src: ImgPort3,
    width: 3,
    height: 4,
  },
  {
    src: ImgLand2,
    width: 3,
    height: 2,
  },
  {
    src: ImgPort4,
    width: 3,
    height: 4,
  },

  {
    src: Dre5,
    width: 1,
    height: 1,
  },
  {
    src: ImgPort5,
    width: 2,
    height: 3,
  },
  {
    src: ImgLand4,
    width: 3,
    height: 2,
  },

  {
    src: ImageSq6,
    width: 1,
    height: 1,
  },
  {
    src: ImgPort8,
    width: 3,
    height: 4,
  },
  {
    src: ImgLand5,
    width: 4,
    height: 3,
  },
  {
    src: ImgPort9,
    width: 2,
    height: 3,
  },
  {
    src: ImgPort10,
    width: 2,
    height: 3,
  },
  {
    src: ImgPort2,
    width: 3,
    height: 4,
  },
  {
    src: ImgPort6,
    width: 3,
    height: 4,
  },
];
