import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query';
import { turq } from '../../utilities/colors';

const Container = styled.div`
    /* border: 1px blue dashed; */
    grid-area: 2 / 1 / 3 / 2;
    display: flex;
    flex-direction: column;
    background-color: black;
    width: auto;
    height: 100px;
    justify-content: center;
    align-content: center;
    ${media.lessThan("medium")`
    `}
    ${media.between("medium", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`;

const BlurbText = styled.p`
    /* border: 1px red dashed; */
    width: 600px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.05em;
    z-index: 1;
    color: ${turq};
    align-self: center;
    margin-right: 5px;
    margin-left: 5px;
    text-align: center;
    margin-bottom: 35px;
    ${media.lessThan("medium")`
        width: 300px;
    `}
    ${media.between("medium", "large")`
    `}
`;

export default function ArtistInfo() {
    return (
        <Container>

        </Container>
    )
}
