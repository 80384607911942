import React from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { gold, purple, turq } from "../../utilities/colors";
import { SocialIcon } from "react-social-icons";
import { Link } from "react-scroll";

const Container = styled.div`
  /* border: 1px red dashed; */
  grid-area: 6 / 1 / 7 / -1;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-end;
  background-color: black;
`;

const BtnView = styled.button`
  width: 200px;
  height: 50px;
  background: transparent;
  border: none;
  margin-top: 20px;
  outline: none;
  color: ${turq};
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 1em;
  align-self: center;
  margin-bottom: 20px;
  cursor: pointer;
  transition-duration: 0.5s;
  &:hover {
    opacity: 0.9;
    transform: scale(1.2);
  }
  ${media.lessThan("medium")`
        /* margin-left: 20px; */
    `}
`;

const CopyrightText = styled.p`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 101.5%;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${turq};
  margin-bottom: 20px;
`;

const SocialsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  margin-bottom: 30px;
`;

const SocialIconContainer = styled.div`
  transition-duration: 0.5s;
  &:hover {
    transform: scale(1.1);
  }
`;

export default function Footer() {
  return (
    <Container>
      <Link
        style={{ alignSelf: "center" }}
        activeClass="active"
        to="top"
        spy={true}
        smooth={true}
        duration={2000}
      >
        <BtnView>Back to Top</BtnView>
      </Link>
      <SocialsContainer>
        <SocialIconContainer>
          <SocialIcon
            url="https://www.instagram.com/dirtyrazzlejazz/"
            network="instagram"
            bgColor="#08EFE5"
            fgColor={"black"}
            style={{ height: 40, width: 40, marginRight: 20 }}
          />
        </SocialIconContainer>
        <SocialIconContainer>
          <SocialIcon
            url="https://www.facebook.com/Andreas-T-194475813959265"
            network="facebook"
            bgColor="#08EFE5"
            fgColor={"black"}
            style={{ height: 40, width: 40 }}
          />
        </SocialIconContainer>
      </SocialsContainer>
      <CopyrightText>Copyright © 2024. All rights reserved.</CopyrightText>
    </Container>
  );
}
