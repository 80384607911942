import React, { Component } from "react";
import { Parallax } from "react-parallax";
import Image2 from "./../../images/dre2.jpeg";
import styled from "styled-components";
import media from "styled-media-query";

const ParallaxContainer = styled.div`
  grid-area: 3 / 1 / 4 / -1;
  /* margin-top: 120px; */
  /* padding-bottom: 80px; */
`;

const ParallaxInner = styled.div`
  height: 400px;
  /* align-self: flex-start; */
  ${media.lessThan("medium")`
   height: 150px;
        `}
  ${media.between("medium", "large")`
    height: 350px;
    `}
    ${media.greaterThan("large")`
    `}
`;

class PaintingParallax extends Component {
  render() {
    return (
      <ParallaxContainer>
        <Parallax bgImage={Image2} strength={400}>
          <ParallaxInner />
        </Parallax>
      </ParallaxContainer>
    );
  }
}

export default PaintingParallax;
