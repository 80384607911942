import React, { Component } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { turq } from "../../utilities/colors";
import { SocialIcon } from "react-social-icons";
import { Link } from "react-scroll";

const Container = styled.div`
  /* border: 1px blue dashed; */
  /* background: black; */
  border-radius: 20px;
  padding: 40px 40px;
  /* opacity: 0.3; */
  grid-area: 1 / 1 / 2 / 2;
  max-width: 1440px;
  display: flex;
  width: auto;
  height: auto;
  flex-direction: column;
  justify-self: center;
  align-content: space-evenly;
  align-self: center;
  ${media.lessThan("medium")`
    `}
  ${media.between("medium", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`;

const HeaderContainer = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  flex-direction: column;
  align-self: center;
`;

const HeaderText = styled.h1`
  /* border: 1px red dashed; */
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  letter-spacing: 0.2px;
  z-index: 1;
  color: white;
  text-shadow: 0px 0px 5px black;
  margin-bottom: 10px;
  align-self: center;
  ${media.lessThan("medium")`
    font-size: 45px;
    `}
  ${media.between("medium", "large")`
    `}
`;

const BlurbText = styled.h1`
  /* border: 1px red dashed; */
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 26px;
  letter-spacing: -0.01em;
  z-index: 1;
  color: white;
  text-shadow: 0px 0px 10px black;
  margin-bottom: 0px;
  align-self: center;
  ${media.lessThan("medium")`
    font-size: 20px;
    `}
  ${media.between("medium", "large")`
    `}
`;

const BlurbText2 = styled(BlurbText)`
  font-size: 18px;
  font-weight: 400;
  margin-top: 5px;
`;

const InfoContainer = styled.div`
  /* border: 2px green dashed; */
  width: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 1;
  justify-content: center;
  ${media.lessThan("medium")`
    `}
  ${media.between("medium", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`;

const LowerContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
  ${media.lessThan("medium")`
    flex-direction: column;
    `}
  ${media.between("medium", "large")`
    `}
`;

const Button = styled.button`
  /* border: 1px blue dashed; */
  width: 230px;
  height: 65px;
  margin-top: 10px;
  background-color: #000;
  border: 3px solid ${turq};
  box-shadow: 0px 0px 5px ${turq};
  box-sizing: border-box;
  border-radius: 36px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: ${turq};
  align-self: center;
  cursor: pointer;
  transition-duration: 0.5s;
  &:hover {
    background-color: ${turq};
    color: #000;
    opacity: 0.9;
    transform: scale(1.05);
  }
  ${media.lessThan("medium")`
        margin-right: 0px;
        margin-top: 10px;
        width: 200px;
        height: 55px;
    `}
  ${media.between("medium", "large")`
      align-self: center;
      margin-right: 10px;
    `}
    ${media.greaterThan("large")`
        margin-right: 10px;
    `}
`;

const SocialsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
`;

const SocialIconContainer = styled.div`
  transition-duration: 0.5s;
  &:hover {
    transform: scale(1.1);
  }
`;

const ButtonContainer = styled.div`
  align-self: center;
`;

export default class Landing extends Component {
  render() {
    return (
      <Container id="top">
        <HeaderContainer>
          <HeaderText
            style={{ fontSize: 30 }}
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-easing="ease-in-sine"
            data-aos-once="true"
          >
            THE ART OF
          </HeaderText>
          <HeaderText
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-easing="ease-in-sine"
            data-aos-once="true"
          >
            ANDREAS
          </HeaderText>
          <HeaderText
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-easing="ease-in-sine"
            data-aos-once="true"
          >
            TIEDEMANN
          </HeaderText>
          <BlurbText
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-easing="ease-in-sine"
            data-aos-once="true"
          >
            Creative Art Pioneer
          </BlurbText>
        </HeaderContainer>
        <InfoContainer>
          <LowerContainer>
            <ButtonContainer
              data-aos="fade-in"
              data-aos-duration="1000"
              data-aos-delay="100"
              data-aos-easing="ease-in-sine"
              data-aos-once="true"
            >
              <a
                style={{
                  alignSelf: "center",
                  textDecoration: "none",
                }}
                href="https://5174db-4.myshopify.com/"
                target="_blank"
                rel="noreferrer"
              >
                <Button>SHOP</Button>
              </a>
            </ButtonContainer>
            <ButtonContainer
              data-aos="fade-in"
              data-aos-duration="1000"
              data-aos-delay="100"
              data-aos-easing="ease-in-sine"
              data-aos-once="true"
            >
              <Link
                style={{ alignSelf: "center" }}
                activeClass="active"
                to="gallery"
                spy={true}
                smooth={true}
                duration={2000}
              >
                <Button>GALLERY</Button>
              </Link>
            </ButtonContainer>
            <ButtonContainer
              data-aos="fade-in"
              data-aos-duration="1000"
              data-aos-delay="200"
              data-aos-easing="ease-in-sine"
              data-aos-once="true"
            >
              <Link
                style={{ alignSelf: "center" }}
                activeClass="active"
                to="contact"
                spy={true}
                smooth={true}
                duration={2000}
              >
                <Button>CONTACT</Button>
              </Link>
            </ButtonContainer>
          </LowerContainer>
          <SocialsContainer
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-delay="300"
            data-aos-easing="ease-in-sine"
            data-aos-once="true"
          >
            <SocialIconContainer>
              <SocialIcon
                url="https://www.instagram.com/dirtyrazzlejazz/"
                network="instagram"
                bgColor="white"
                fgColor={"black"}
                style={{ height: 40, width: 40, marginRight: 20 }}
              />
            </SocialIconContainer>
            <SocialIconContainer>
              <SocialIcon
                url="https://www.facebook.com/Andreas-T-194475813959265"
                network="facebook"
                bgColor="white"
                fgColor={"black"}
                style={{ height: 40, width: 40, marginRight: 10 }}
              />
            </SocialIconContainer>
          </SocialsContainer>
        </InfoContainer>
      </Container>
    );
  }
}
